import React from "react";
// import styles from "./ContactUs.module.scss";
import useLang from "../../contexts/Language/LanguageContext";
import HomeIcon from "@material-ui/icons/Home";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import MailIcon from "@material-ui/icons/Mail";
import BusinessIcon from "@material-ui/icons/Business";
import RouterIcon from "@material-ui/icons/Router";

export default function ContactUs() {
  const lang = useLang();
  return (
    <div>
      <div>
        <p style={{ display: "flex", alignItems: "center" }}>
          <BusinessIcon style={{ color: "white", marginRight: "10px" }} />
          {lang === "mne" ? "Adresa:" : "Address:"}
        </p>
        <p>
          Stari grad 454, 85330 Kotor,{" "}
          {lang === "mne" ? "Crna Gora" : "Montenegro"}
        </p>
      </div>
      <div>
        <p style={{ display: "flex", alignItems: "center" }}>
          <PhoneAndroidIcon style={{ color: "white", marginRight: "10px" }} />
          {lang === "mne" ? "Kontakt podaci:" : "Contact:"}
        </p>
        <p>{lang === "mne" ? "Opšte informacije:" : "Informations:"}</p>
        <a
          style={{ display: "block", marginBottom: 5 }}
          href="tel:0038268022803"
        >
          +382 68 022 803
        </a>
        <a style={{ display: "block" }} href="mailto:info@imiboke.me">
          info@imiboke.me
        </a>
        <p>
          {lang === "mne"
            ? "Usluge i servisi podrške:"
            : "Services and support:"}
        </p>
        <a
          style={{ display: "block", marginBottom: 5 }}
          href="tel:0038268022805"
        >
          +382 68 022 805
        </a>
        <a
          style={{ display: "block", marginBottom: 5 }}
          href="tel:0038269504974"
        >
          +382 69 504 974
        </a>
        <a style={{ display: "block" }} href="mailto:podrska@imiboke.me">
          podrska@imiboke.me
        </a>
        <p>{lang === "mne" ? "Odnosi sa javnošću:" : "Public relations:"}</p>
        <a
          style={{ display: "block", marginBottom: 5 }}
          href="tel:0038267123240 "
        >
          +382 67 123 240
        </a>
        <a style={{ display: "block" }} href="mailto:pr@imiboke.me">
          pr@imiboke.me
        </a>
        <p>{lang === "mne" ? "Finansije:" : "Finance:"}</p>
        <a
          style={{ display: "block", marginBottom: 5 }}
          href="tel:0038268022809"
        >
          +382 (0) 68 022 809
        </a>
        <a style={{ display: "block" }} href="mailto:finansije@imiboke.me">
          finansije@imiboke.me
        </a>
      </div>
      {/* <div>
        <p style={{ display: "flex", alignItems: "center" }}>
          <MailIcon style={{ color: "white", marginRight: "10px" }} />
          {lang === "mne" ? "E-mail:" : "E-mail:"}
        </p>
        <a href="mailto:info@imiboke.me">info@imiboke.me</a>
      </div> */}
      {/* <div>
        <p style={{ display: "flex", alignItems: "center" }}>
          <BusinessIcon style={{ color: "white", marginRight: "10px" }} />
          {lang === "mne" ? "Društvene mreže:" : "Social networks:"}
        </p>
        <a></a>
      </div> */}
      <p>
        <a
          href="https://invite.viber.com/?g2=AQBjpGFN%2BKRFnE3jXHpYc8b01kyBe5ZPiIANtFkL%2FPqoKMhT4xm0Sqq%2BwBvFvvKq"
          target="_blank"
        >
          {lang === "en"
            ? "Join the IYDB’s Viber Community"
            : "Pridruži se Viber zajednici I MI Boke"}
        </a>
      </p>
    </div>
  );
}
